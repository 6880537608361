const translateVar = (props) => {
  const VarTranslation = {
    TEMP: "Temperature (°C)",
    PRES: "Pressure (dbar)",
    PSAL: "Practical Salinity",
    DOXY: "Dissolved Oxygen (μmol/kg)",
    CHLA: "Chlorophyll a (mg/m<sup>3</sup>)",
    BBP700: "Particle backscattering at 700 nm (m<sup>-1</sup>)",
    PH_IN_SITU_TOTAL: "pH total scale",
    NITRATE: "Nitrate (μmol/kg)",
    CDOM: "Coloured dissolved organic matter (ppb)",
    AirPumpVolts: "Air Pump",
    BuoyancyPumpVolts: "Buoyancy Pump",
    QuiescentVolts: "Quiescent Volts",
    Sbe41cpVolts: "CTD - SBE41cp",
    Sbe63Volts: "DOY - SBE63",
    McomsVolts: "MCOMS",
    AirPumpAmps: "Air Pump",
    BuoyancyPumpAmps: "Buoyancy Pump",
    QuiescentAmps: "Quiescent Amps",
    Sbe41cpAmps: "CTD - SBE41cp",
    Sbe63Amps: "DOY - SBE63",
    McomsAmps: "MCOMS",
    CurrentBuoyancyPosition: "Current Buoyancy Position",
    DeepProfileBuoyancyPosition: "Deep Profile Buoyancy Position",
    ParkBuoyancyPosition: "Park Buoyancy Position",
    SurfaceBuoyancyPosition: "Surface Buoyancy Position",
    AirBladderPressure: "Air Bladder Pressure",
    BuoyancyPumpOnTime: "Buoyancy Pump On Time",
    TimeStartDescent_delta: "Start Descent",
    GpsFixDate_delta: "GPS Fix",
    TimeStartPark_delta: "Time Start Park",
    TimeStartProfileDescent_delta: "Time Start Profile Descent",
    TimeStartProfile_delta: "Time Start Profile",
    TimeStopProfile_delta: "Time Stop Profile",
    TimeStartTelemetry_delta: "Time Start Telemetry",
    GPS_DURATION_plotly: "GPS",
    TRANS_DURATION_plotly: "Transmission",
    CONNECTION_ATTEMPTS: "Attempted",
    CONNECTIONS: "Sucessful",
    UPLOAD_ATTEMPTS: "Attempted",
    UPLOADS: "Successful",
    Vacuum: "Internal Vacuum",
  };

  return VarTranslation[props];
};

export default translateVar;
